import { CommonModule } from '@angular/common';
import { AfterContentInit, Component, ContentChild, EventEmitter, Input, OnDestroy, OnInit, Output, forwardRef } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { Subscription, noop, pairwise, startWith } from 'rxjs';
import { ComponentSize } from '../../../data-model/types/component-size.type';
import { InputType } from '../../../data-model/types/input-type.type';
import { CommonUtils } from '../../../utils/common-utils';
import { StringUtils } from '../../../utils/string-utils';
import { HelpTextComponent } from '../help-text/help-text.component';
import { LabelComponent } from '../label/label.component';
import { OssErrorStateMatcher } from '../oss-error-state-matcher';
import { OssFieldErrorComponent } from '../oss-field-error/oss-field-error.component';

@Component({
  selector: 'app-oss-input',
  templateUrl: './oss-input.component.html',
  styleUrls: ['./oss-input.component.scss'],
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, MatInputModule, MatIconModule, LabelComponent, HelpTextComponent],
  providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => OssInputComponent), multi: true }],
})
export class OssInputComponent implements ControlValueAccessor, OnInit, AfterContentInit, OnDestroy {
  @ContentChild(OssFieldErrorComponent, { static: false }) fieldError: OssFieldErrorComponent;

  @Input({ required: false }) helpText: string = StringUtils.EMPTY;
  @Input({ required: false }) icon: string;
  @Input({ required: false }) iconClass: string = StringUtils.EMPTY;
  @Input({ required: false }) isDate: boolean = false;
  @Input({ required: false }) isPhone: boolean = false;
  @Input({ required: false }) isRequired: boolean = false;
  @Input({ required: false }) isTime: boolean = false;
  @Input({ required: false }) label: string = StringUtils.EMPTY;
  @Input({ required: false }) labelOverride: string = StringUtils.EMPTY;
  @Input({ required: false }) labelPosition: 'top' | 'left' = 'top';
  @Input({ required: false }) maxLength: number = 255;
  @Input({ required: false }) placeholder: string = StringUtils.EMPTY;
  @Input({ required: false }) showLabel: boolean = true;
  @Input({ required: false }) showRequiredAsterisk: boolean = true;
  @Input({ required: false }) showSubscript: boolean = true;
  @Input({ required: false }) type: InputType = 'text';
  @Input({ required: false }) width: ComponentSize = 'm';

  @Input({ required: false }) set isDisabled(isDisabled: boolean) {
    this.setDisabledState(isDisabled);
  }

  @Output() blurEvent = new EventEmitter<{ value: unknown; hasChanges: boolean }>();

  errorStateMatcher: OssErrorStateMatcher;
  formControl: FormControl;

  private formControlSubscription: Subscription;
  private onChange: (value: unknown) => void = noop;
  private onTouched: () => void = noop;

  ngOnInit(): void {
    this.formControl = new FormControl(StringUtils.EMPTY);

    // if we don't add a startWith(null) here, the first value change will not be emitted
    // pairwise() needs two values to emit the first change
    this.formControlSubscription = this.formControl.valueChanges.pipe(startWith(null), pairwise()).subscribe(([previousValue, currentValue]) => {
      let newValue = currentValue;

      if (this.isPhone) {
        if (newValue?.length > previousValue?.length && (/^\d{3}$/.test(newValue) || /^\d{3}-\d{3}$/.test(newValue))) {
          newValue = `${newValue}-`;
        }
        if (newValue !== previousValue) {
          this.formControl.setValue(newValue, { emitEvent: false });
        }
      }

      if (this.isDate) {
        if (newValue?.length > previousValue?.length && (/^\d{2}$/.test(newValue) || /^\d{2}\/\d{2}$/.test(newValue))) {
          newValue = `${newValue}/`;
        }
        if (newValue !== previousValue) {
          this.formControl.setValue(newValue, { emitEvent: false });
        }
      }

      this.onChange(newValue);
      this.onTouched();
      this.removeInvalidClass();
    });
  }

  ngAfterContentInit(): void {
    this.errorStateMatcher = new OssErrorStateMatcher(this.fieldError);
  }

  ngOnDestroy(): void {
    if (CommonUtils.isDefined(this.formControlSubscription)) {
      this.formControlSubscription.unsubscribe();
    }
  }

  writeValue(value: any): void {
    if (CommonUtils.isNullOrUndefined(value) || value === StringUtils.EMPTY) {
      this.formControl.setValue(value, { emitEvent: false });
      return;
    }
    let formattedValue: string = value.toString();
    if (this.isDate) {
      const date = new Date(value);
      if (!isNaN(date.getTime())) {
        // commented out for reference -- we were using a four-digit year and before switching to two-digit year to 'save the user time'
        // formattedValue = date.toLocaleDateString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' });
        formattedValue = `${String(date.getMonth() + 1).padStart(2, '0')}/${String(date.getDate()).padStart(2, '0')}/${date.getFullYear().toString().slice(-2)}`;
      } else {
        formattedValue = StringUtils.EMPTY;
      }
    }
    this.formControl.setValue(formattedValue, { emitEvent: false });
  }

  registerOnChange(onChange: (value: unknown) => void): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: () => void): void {
    this.onTouched = onTouched;
  }

  setDisabledState(isDisabled: boolean): void {
    if (CommonUtils.isNullOrUndefined(this.formControl)) {
      return;
    }
    const opts = { emitEvent: false };
    if (isDisabled) {
      this.formControl.disable(opts);
      return;
    }
    this.formControl.enable(opts);
  }

  onBlur(): void {
    this.removeInvalidClass();
    this.blurEvent.emit({ value: this.formControl.value, hasChanges: this.formControl.dirty });
  }

  removeFocusedClass(): void {
    document.querySelectorAll('.mat-mdc-text-field-wrapper').forEach(element => element.classList.remove('mdc-text-field--focused'));
  }

  private removeInvalidClass(): void {
    setTimeout(() => {
      if (this.fieldError?._error) {
        document.querySelectorAll('.mat-mdc-text-field-wrapper').forEach(element => element.classList.remove('mdc-text-field--invalid'));
      }
    });
  }
}
