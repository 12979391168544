<div
  class="oss-form-control-container"
  [ngClass]="{
    'label-above': labelPosition === 'top',
    'label-left': labelPosition === 'left'
  }"
>
  @if (showLabel) {
    <app-label [label]="label" [isRequired]="isRequired"></app-label>
  }

  @if (helpText !== null && helpText !== undefined && helpText.length) {
    <app-help-text [text]="helpText"></app-help-text>
  }

  <mat-form-field
    appearance="outline"
    class="oss-form-control-form-field"
    [ngClass]="{
      'hide-subscript': showSubscript === false,
      'width-xs': width === 'xs',
      'width-s': width === 's',
      'width-m': width === 'm',
      'width-l': width === 'l',
      'width-xl': width === 'xl',
      'width-xxl': width === 'xxl'
    }"
  >
    <input
      matInput
      [formControl]="formControl"
      [placeholder]="placeholder ? placeholder : showLabel ? label : ''"
      [maxLength]="isPhone ? 12 : isDate ? 10 : maxLength"
      (blur)="onBlur()"
      (focusin)="removeFocusedClass()"
      [type]="type"
      [errorStateMatcher]="errorStateMatcher"
      class="oss-form-control-input"
    />

    <mat-error>
      <ng-content select="app-oss-field-error"></ng-content>
    </mat-error>
  </mat-form-field>
</div>
