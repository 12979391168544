import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-help-text',
  standalone: true,
  imports: [],
  templateUrl: './help-text.component.html',
  styleUrl: './help-text.component.scss',
})
export class HelpTextComponent {
  @Input({ required: true }) text: string;
}
