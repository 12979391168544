import { StringUtils } from './string-utils';

export class CommonUtils {
  static isNullOrUndefined(value: unknown): boolean {
    return value === null || value === undefined;
  }

  static isNull(value: unknown): boolean {
    return value === null;
  }

  static isDefined(value: unknown): boolean {
    return !this.isNullOrUndefined(value);
  }

  static isDefinedAndNonEmptyString(value: unknown): boolean {
    return this.isDefined(value) && value !== StringUtils.EMPTY;
  }

  static isNullOrUndefinedOrEmptyString(value: unknown): boolean {
    return this.isNullOrUndefined(value) || value === StringUtils.EMPTY;
  }

  static isEmpty(value: unknown): boolean {
    if (this.isNullOrUndefined(value)) {
      return true;
    }
    if (typeof value === 'string' && value.trim().length === 0) {
      return true;
    }
    if (Array.isArray(value) && value.length === 0) {
      return true;
    }
    if (typeof value === 'object' && Object.keys(value).length === 0) {
      return true;
    }
    return false;
  }

  static hasContent(value: unknown): boolean {
    return !this.isEmpty(value);
  }

  static isArray(value: unknown): boolean {
    return Array.isArray(value);
  }

  static assert(condition: boolean, message: string = 'Assertion failed'): asserts condition {
    if (!condition) {
      throw new Error(message);
    }
  }

  static isDefinedAndTrue(value: unknown): boolean {
    return this.isDefined(value) && value === true;
  }

  static isDefinedAndFalse(value: unknown): boolean {
    return this.isDefined(value) && value === false;
  }

  static identity<T>(value: T): T {
    return value;
  }
}
