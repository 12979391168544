import { Component, Input } from '@angular/core';
import { FormControl, FormGroupDirective, NgForm, ValidationErrors } from '@angular/forms';
import { CommonUtils } from '../../../utils/common-utils';

@Component({
  selector: 'app-oss-field-error',
  templateUrl: './oss-field-error.component.html',
  styleUrl: './oss-field-error.component.scss',
  standalone: true,
})
export class OssFieldErrorComponent {
  @Input({ required: true }) set error(error: ValidationErrors | null) {
    this._error = error;
  }

  _error: ValidationErrors | null;

  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    return CommonUtils.isDefined(this._error) && control.touched;
  }
}
