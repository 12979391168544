import { TechnicalException } from '../data-model/models/technical-exception.model';

export class StringUtils {
  static readonly EMPTY = '';
  static readonly SPACE = ' ';

  static isString(value: unknown): boolean {
    return typeof value === 'string';
  }

  static transformToTitleCase(value: string): string {
    if (!this.isString(value)) {
      throw new TechnicalException('value provided to StringUtils::transformToTitleCase method is not a string');
    }
    return value.replace(/\w\S*/g, txt => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
  }

  static replaceCharacters(value: string, target: string, replacement: string): string {
    if (!this.isString(value)) {
      throw new TechnicalException('value provided to StringUtils::replaceCharacters is not a string');
    }
    return value.split(target).join(replacement);
  }

  static clone(value: string): string {
    return [...value].join('');
  }

  static isEmpty(value: string): boolean {
    return value === StringUtils.EMPTY;
  }

  static isNotEmpty(value: string): boolean {
    return value !== StringUtils.EMPTY;
  }
}
