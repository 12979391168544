import { CommonUtils } from './common-utils';

export class OssOptional<T> {
  private constructor(private value: T | null) {}

  static ofNullable<T>(value: T | null): OssOptional<T> {
    return new OssOptional<T>(value);
  }

  static of<T>(value: T): OssOptional<T> {
    return new OssOptional<T>(value);
  }

  ifPresent(action: (value: T) => void): OssOptional<T> {
    if (CommonUtils.isDefined(this.value)) {
      action(this.value);
    }
    return this;
  }

  ifPresentOrElse(action: (value: T) => void, emptyAction: () => void): OssOptional<T> {
    if (CommonUtils.isDefined(this.value)) {
      action(this.value);
    } else {
      emptyAction();
    }
    return this;
  }

  map<R>(mapper: (value: T) => R): OssOptional<R> {
    if (CommonUtils.isNullOrUndefined(this.value)) {
      return new OssOptional<R>(null);
    } else {
      return new OssOptional<R>(mapper(this.value));
    }
  }

  orElse(other: T): T {
    return CommonUtils.isNullOrUndefined(this.value) ? other : this.value;
  }

  orElseThrow(errorSupplier: () => Error): T {
    if (CommonUtils.isNullOrUndefined(this.value)) {
      throw errorSupplier();
    }
    return this.value;
  }

  orElseNull(): T | null {
    return this.value;
  }
}
