@if (_error) {
  @if (_error['minlength']) {
    <div>
      Must be no shorter than
      {{ _error['minlength'].requiredLength }} characters
    </div>
  }

  @if (_error['maxlength']) {
    <div>Must be no longer than {{ _error['maxlength'].requiredLength }} characters</div>
  }

  @if (_error['greaterThan']) {
    <div>Must be greater than {{ _error['greaterThan'].value }}</div>
  }

  @if (_error['greaterThanOrEqualTo']) {
    <div>Must be greater than or equal to {{ _error['greaterThan'].value }}</div>
  }

  @if (_error['lessThan']) {
    <div>Must be less than {{ _error['lessThan'].value }}</div>
  }

  @if (_error['lessThanOrEqualTo']) {
    <div>Must be less than or equal to {{ _error['lessThanOrEqualTo'].value }}</div>
  }

  @if (_error['equals']) {
    <div>Must equal {{ _error['equals']['values'] }}</div>
  }

  @if (_error['doesNotEqual']) {
    <div>Must not equal {{ _error['doesNotEqual']['values'] }}</div>
  }

  @if (_error['dateLessThan']) {
    <div>Must be less than {{ _error['dateLessThan']['value'] }}</div>
  }

  @if (_error['dateLessThanOrEqualTo']) {
    <div>Must be less than or equal to {{ _error['dateLessThanOrEqualTo']['value'] }}</div>
  }

  @if (_error['dateGreaterThan']) {
    <div>Must be greater than {{ _error['dateGreaterThan']['value'] }}</div>
  }

  @if (_error['dateGreaterThanOrEqualTo']) {
    <div>Must be greater than or equal to {{ _error['dateGreaterThanOrEqualTo']['value'] }}</div>
  }

  @if (_error['timeLessThan']) {
    <div>Must be less than {{ _error['timeLessThan']['value'] }}</div>
  }

  @if (_error['timeLessThanOrEqualTo']) {
    <div>Must be less than or equal to {{ _error['timeLessThanOrEqualTo']['value'] }}</div>
  }

  @if (_error['timeGreaterThan']) {
    <div>Must be greater than {{ _error['timeGreaterThan']['value'] }}</div>
  }

  @if (_error['timeGreaterThanOrEqualTo']) {
    <div>Must be greater than or equal to {{ _error['timeGreaterThanOrEqualTo']['value'] }}</div>
  }

  @if (_error['required']) {
    <div>Required</div>
  }

  @if (_error['doesNotMatch']) {
    <div>Must match {{ _error['doesNotMatch']['targetFormControlName'] }}</div>
  }

  @if (_error['invalidItemNumber']) {
    <div>
      @if (_error['invalidItemNumber']['format'] && _error['invalidItemNumber']['example']) {
        Must be in format {{ _error['invalidItemNumber']['format'] }} ({{ _error['invalidItemNumber']['example'] }})
      }
      @if (_error['invalidItemNumber']['helpText']) {
        {{ _error['invalidItemNumber']['helpText'] }}
      }
    </div>
  }

  @if (_error['invalidTime'] && _error['invalidTime']['invalidFormat']) {
    <div>Must be in format {{ _error['invalidTime']['invalidFormat'] }}</div>
  }

  @if (_error['invalidTime'] && _error['invalidTime']['invalidMilitaryTime']) {
    <div>Must be valid military time</div>
  }

  @if (_error['invalidDate']) {
    <div>Must be in format {{ _error['invalidDate']['format'] }}</div>
  }

  @if (_error['invalidYear']) {
    <div>Year must be greater than {{ _error['invalidYear']['min'] }}</div>
  }

  @if (_error['invalidMonth']) {
    <div>Month must be between {{ _error['invalidMonth']['min'] }} and {{ _error['invalidMonth']['max'] }}</div>
  }

  @if (_error['invalidDay']) {
    <div>Day must be between {{ _error['invalidDay']['min'] }} and {{ _error['invalidDay']['max'] }}</div>
  }

  @if (_error['vin']) {
    <div>{{ _error['vin']['message'] }}</div>
  }

  @if (_error['invalidNumber']) {
    <div>{{ _error['invalidNumber'] }} is not a number</div>
  }

  @if (_error['phoneNumber']) {
    <div>{{ _error['phoneNumber']['message'] }}</div>
  }

  @if (_error['email']) {
    <div>Must enter a valid email address</div>
  }

  @if (_error['alphanumeric']) {
    <div>Must contain numbers or letters only (no special characters)</div>
  }

  @if (_error['invalidUnitNumber']) {
    <div>Must be between OSS01 and OSS14</div>
  }

  @if (_error['invalidDisposition']) {
    <div>Invalid disposition</div>
  }
}
