import { createAction, props } from '@ngrx/store';
import { ClientCurrentUser } from '../../data-model/models/client-current-user.model';
import { SignUpRequest } from '../../data-model/models/sign-up-request.model';

const feature = '[Auth]';

/**
 * sign in
 */
export const signIn = createAction(`${feature} Sign In`, props<{ username: string; password: string }>());
export const signedIn = createAction(`${feature} Signed In`, props<{ currentUser: ClientCurrentUser; apiToken: string }>());

/**
 * sign out
 */
export const signOut = createAction(`${feature} Sign Out`);
export const signedOut = createAction(`${feature} Signed Out`);

/**
 * forgot password
 */
export const forgotPassword = createAction(`${feature} Forgot Password`, props<{ email: string; username: string }>());
export const forgotPasswordSuccessful = createAction(`${feature} Forgot Password Successful`);

/**
 * reset password
 */
export const resetPassword = createAction(`${feature} Reset Password`, props<{ password: string; token: string }>());
export const resetPasswordSuccessful = createAction(`${feature} Reset Password Successful`);

/**
 * sign up
 */
export const signUp = createAction(`${feature} Sign Up`, props<{ request: SignUpRequest }>());
export const signUpSuccessful = createAction(`${feature} Sign Up Successful`);
