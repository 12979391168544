import { FormControl, FormGroupDirective, NgForm } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { CommonUtils } from '../../utils/common-utils';
import { OssFieldErrorComponent } from './oss-field-error/oss-field-error.component';

export class OssErrorStateMatcher implements ErrorStateMatcher {
  constructor(private ossFieldErrorComponent: OssFieldErrorComponent) {}

  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    if (CommonUtils.isNullOrUndefined(this.ossFieldErrorComponent)) {
      return control.invalid && control.touched;
    }
    return this.ossFieldErrorComponent.isErrorState(control, form);
  }
}
